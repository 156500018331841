<template>
  <div>
    <div class="relative i-border-1 border-secondary-two rounded w-full i-h-50"
         @click="placeholder.search = ''"
         v-click-outside="hideSearchPlaceholder">
      <input
          type="text"
          class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two i-h-50"
          :placeholder="placeholder.search"
          v-model="searchQuery"
      />
    </div>

    <div class="bg-primary-three shadow-one rounded mt-8 mb-8 relative flex items-center px-4 i-h-50 bld-level-w">
      <h2 class="font-poppins fs-14 fw-600 text-primary-one">{{ selectedTitle }}</h2>
      <div v-click-outside="hideDropdown">
        <div class="absolute bld-global-chevron w-10 i-h-50 cursor-pointer flex justify-center items-center"
             @click="toggleDropdown()">
          <img src="/images/icons/chevron_down.svg" alt="chevron" :class="{'rotate-180': dropdownStat}">
        </div>
        <div
            class="absolute bg-primary-three bld-level-w text-left px-5 py-3 rounded shadow-one bld-global-opt flex flex-col"
            v-if="dropdownStat">
          <div>
            <h3 class="fs-14 fw-600 text-primary-one font-poppins">Workspace Library</h3>
            <h3 class="fs-12 text-secondary-one font-poppins mt-3">Filters:</h3>
            <div class="flex mt-3">
              <h2 class="fs-12 font-poppins text-primary-three bg-primary-four flex justify-center items-center def-type rounded cursor-pointer px-2" :class="{'border-2 border-primary-one': loadType === 1}" @click="setLoadType(1)">Slider</h2>
              <h2 class="fs-12 font-poppins text-primary-three bg-static-one flex justify-center items-center def-type rounded cursor-pointer i-ml-5 px-2" :class="{'border-2 border-primary-one': loadType === 2}" @click="setLoadType(2)">Level</h2>
              <h2 class="fs-12 font-poppins text-primary-three bg-static-two flex justify-center items-center def-type rounded cursor-pointer i-ml-5 px-2" :class="{'border-2 border-primary-one': loadType === 3}" @click="setLoadType(3)">Business</h2>
            </div>
          </div>
          <div class="flex flex-col mt-5">
            <div class="flex justify-between items-center i-n-mr-10">
              <h3 class="fs-14 fw-600 text-primary-one font-poppins">Projects </h3>
              <img src="/images/icons/chevron_down.svg" alt="icon" class="cursor-pointer" @click="menuExpand = !menuExpand" :class="{'rotate-180': menuExpand}">
            </div>
            <div v-show="menuExpand" class="mt-4 bld-global-project-div overflow-y-auto ws-scrollbar">
              <div class="font-poppins fs-12 fw-600 cursor-pointer text-primary-one opacity-75"
                   v-for="(projectItem, prIndex) in buildGlobalProjects"
                   :key="prIndex"
                   :class="{'mt-4': prIndex > 0, 'fs-14 opacity-100': projectSelection === projectItem.id}"
                   @click="toggleProjectSelection(prIndex, projectItem.id)">
                {{ projectItem.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="global-navigation overflow-y-auto">
      <draggable
          :list="filterResult"
          :group="{ name: 'definition_item', pull: 'clone' }"
          v-bind="dragOptions"
          @start="startTheDrag($event)"
          @end="dropTheItem($event)" class="pl-10 pr-10">
        <div class="bg-primary-three bld-global-h bld-level-w rounded shadow-two relative cursor-move i-bg-img-fit flex flex-col justify-end"
             v-for="(item, index) in filterResult" :key="index"
             :class="{'mt-8': index > 0}"
             :style="{'background-image': 'url(' + require(`../../../../assets/images/${demoImages[getRandomIndex()]}`) + ')'}"
             :id="item.id">
          <div class="absolute bld-global-item bld-level-w bg-primary-three py-4 px-4">
            <h2 class="font-poppins fs-14 text-primary-one fw-600 ellipses text-left">{{ item.title }}</h2>
            <p class="font-poppins fs-12 text-left text-primary-one fw-300">
              {{ item.description && item.description.length > 117 ? item.description.substr(0, 117) + '...' : item.description }}
            </p>
          </div>
          <div class="absolute bld-tag bld-tag-size bg-primary-four rounded-sm flex items-center justify-center bg-primary-four">
            <h2 class="font-poppins fs-12 text-primary-three px-2 text-center">{{ types[item.type - 1] }}</h2>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LibraryData",
  created() {
    const self = this;
    self.$store.dispatch("project/loadBuildGlobalProjects", this.$route.params.id);
    self.loadGlobalDefinitions();
  },
  data() {
    return {
      currentList: [],
      types: ['Slider', 'Level', 'Business'],
      loadType: null,
      searchQuery: null,
      placeholder: {
        search: "Search"
      },
      dropdownStat: false,
      selectedTitle: 'Workspace Library',
      menuExpand: true,
      projectSelection: null,
      demoImages: ['bld_demo_1.jpg', 'bld_demo_1.jpg', 'bld_demo_1.jpg']
    }
  },
  computed: {
    ...mapGetters({
      buildGlobalProjects: "project/buildGlobalProjects",
      globalDefinitionItems: "definition/globalDefinitionItems",
      definitionSet: "definition/definitionSet"
    }),

    filterResult() {
      const self = this;
      if (self.searchQuery) {
        return self.globalDefinitionItems.filter((item) => {
          return self.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => {
                if (item.tag) {
                  return item.title.toLowerCase().includes(v) || item.tag.toLowerCase().includes(v);
                }
                return item.title.toLowerCase().includes(v);
              });
        });
      }
      return self.globalDefinitionItems;
    },

    dragOptions() {
      return {
        animation: 200,
        ghostClass: "ghost",
      };
    },
  },
  
  methods: {
    cloneItem() {
      return;
    },
    hideSearchPlaceholder() {
      this.placeholder.search = 'Search';
    },
    hideDropdown() {
      this.dropdownStat = false;
    },
    toggleDropdown() {
      this.dropdownStat = !this.dropdownStat;
    },
    toggleProjectSelection(index, id) {
      this.selectedTitle = this.buildGlobalProjects[index].name;
      this.projectSelection = id;
      this.loadGlobalDefinitions();
      this.toggleDropdown();
    },
    getProjectData(id) {
      //
    },
    setLoadType(type) {
      this.loadType = type;
      this.selectedTitle = this.types[type - 1] + ' definition';

      this.loadGlobalDefinitions();
    },
    loadGlobalDefinitions() {
      const self = this;
      self.$store.dispatch("definition/loadGlobalLibrariesData", {
        project: !!self.projectSelection,
        id: self.projectSelection ? self.projectSelection : parseInt(self.$route.params.id),
        type: self.loadType ? self.loadType : false
      });
    },

    startTheDrag(event) {
      //
    },

    dropTheItem(e) {
      let target = e.to;
      let copiedId = e.item.getAttribute('id');
      if(((target.parentNode && target.parentNode.getAttribute("container")) || target.getAttribute('serial')) && copiedId) {
        if(this.checkCreateAbility(copiedId)) {
          this.$Progress.start();
          this.$store.dispatch("definition/storeDraggedItem", {
            definition_id: this.$route.params.definition_id,
            id: copiedId,
            order: this.definitionSet && this.definitionSet.items && this.definitionSet.items.length > 0 ? this.definitionSet.items[this.definitionSet.items.length - 1].order + 1 : 1
          }).then(response => {
            this.$Progress.finish();
          });
        } else {
          alert("Can not drag this");
        }
      }
    },
    
    checkCreateAbility(id) {
      let items = this.definitionSet && this.definitionSet.items && this.definitionSet.items.length > 0 ? this.definitionSet.items : [];
      let item = this.globalDefinitionItems.find(data => parseInt(data.id) === parseInt(id));
      if(item) {
        if(item.type === 1) {
          return !this.isSliderExist(items);
        } else if(item.type === 2) {
          return !this.isLevelExist(items);
        } else {
          return true;
        }
      }
      return true;
    },
    isSliderExist(items) {
      let isExist = items.findIndex(item => parseInt(item.type) === 1);
      return isExist >= 0;
    },
    isLevelExist(items) {
      let isExist = items.findIndex(item => parseInt(item.type) === 2);
      return isExist >= 0;
    },
    getRandomIndex(min = 0, max = 2) {
      let index = Math.random() * (max - min) + min;
      return parseInt(index);
    },
  }
}
</script>

<style scoped>
.def-type {
  height: 20px;
}
</style>