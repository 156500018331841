<template>
  <div class="w-full font-poppins">
    <div class="_main_wrapper relative flex" :class="fullScreen&&'no-left-panel-visible'">
      <div class="i-left-panel shadow-one fs-14 fw-600 text-primary-three relative" :class="{'hidden': fullScreen}">
        <div class="relative">
          <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
          <NavToggle v-if="projectId" />
        </div>
        <div class="flex mb-8 mt-10">
          <img @click="setActiveSidebar('definition')"
               src="@/assets/images/icons/properties/navigation.svg"
               alt="logo"
               class="i-menu-icon cursor-pointer opacity-50 hover:bg-secondary-three"
               :class="{'opacity-100': activeSidebar === 'definition'}" />
          <img @click="setActiveSidebar('library')"
               src="@/assets/images/icons/build/global_library.svg"
               alt="logo"
               class="i-ml-30 i-menu-icon cursor-pointer opacity-50 hover:bg-secondary-three"
               :class="{'opacity-100': activeSidebar === 'library'}" />
        </div>

        <div class="definition-show-side overflow-y-auto">
          <definition-data v-if="activeSidebar === 'definition'" />
          <library-data v-if="activeSidebar === 'library'" />
        </div>
      </div>

      <div class="i-right-panel" :class="{'def-full-screen': fullScreen}">
        <div class="ws-heading-box relative flex flex-col pb-8">
          <workspace-menu :hideMenu="true" />
        </div>

        <div :container="'true'">
          <div class="definition-content-section pl-10 i-mr-65 flex overflow-x-auto pb-5" :class="{'definition-content-full-screen': fullScreen}">
              <!-- :list="getDefinitionItems" -->
            <draggable
              :list="list"
              v-model="getDefinitionItems"
              :group="{ name: 'definition_item', pull: 'clone' }"
              @end="dropTheItem($event)"
              v-for="(item, index) in getDefinitionItems"
              :key="index">
              <transition-group name="list" :key="'def_' + index" :serial="index">
              <definition-item :id="item.id"
                                :serial="index"
                                :class="{'def-item-ml': index > 0}"
                                :key="'def_' + index"
                                :item="item" />
              </transition-group>
            </draggable>
          </div>
        </div>

        <div class="absolute content-header-fullscreen" v-if="fullScreen">
          <img src="/images/instrat_logo.svg" alt="logo" class="mb-10" />
          <img @click="setActiveSidebar('definition')"
               src="@/assets/images/icons/properties/navigation.svg"
               alt="logo"
               class="i-menu-icon cursor-pointer opacity-50 hover:bg-secondary-three"
               :class="{'opacity-100': activeSidebar === 'definition'}" />
        </div>
      </div>
    </div>

    <Nav v-model="showNavigation" @close="showNavigation=false"/>
  </div>
</template>

<script setup>
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import useNavigation from '@/components/navigation/useNavigation.js'
import Nav from '@/components/navigation/Nav.vue'
const { projectId, showNavigation } = useNavigation()
</script>

<script>
import DefinitionData from "@/components/definition/show/sidebar/DefinitionData";
import LibraryData from "@/components/definition/show/sidebar/LibraryData";
import DefinitionItem from "@/components/definition/show/DefinitionItem";
import {mapGetters} from "vuex";

export default {
  name: "Show",
  components: {DefinitionItem, LibraryData, DefinitionData},
  created() {
    document.title = "Definition";
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    this.$Progress.start();
    this.$store.dispatch("definition/getDefinitionSet", {definition_id: this.$route.params.definition_id}).then(response => {
      if (response) {
        this.$Progress.finish();
      }
    });
  },
  data() {
    return {
      list: [],
      activeSidebar: 'definition',
      clickedNavigation: 1
    }
  },
  computed: {
    ...mapGetters({
      definitionSet: "definition/definitionSet"
    }),
    getDefinitionItems() {
      return this.definitionSet && this.definitionSet.items && this.definitionSet.items.length > 0 ? this.getSortedDefinitions() : [];
    },
    fullScreen() {
      return this.clickedNavigation === 2;
    },
    dragOptions() {
      return {
        animation: 200,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    getSortedDefinitions() {
      return this.definitionSet.items;
      // return this.definitionSet.items.sort(function (a, b) {
      //   return a.type - b.type;
      // });
    },
    setActiveSidebar(type) {
      if(type !== 'definition') {
        this.activeSidebar = type;
      } else {
        if(this.activeSidebar === 'definition') {
          if(this.clickedNavigation === 2) {
            this.clickedNavigation = 1;
          } else {
            this.clickedNavigation = 2;
          }
        } else {
          this.clickedNavigation = 1;
        }
        this.activeSidebar = type;
      }
    },
    cloneItem() {
      return;
    },
    dropTheItem(e)
    {
      let itemSerial = e.item.getAttribute('serial');
      let newSerial = e.to.getAttribute('serial');
      if(itemSerial && newSerial && parseInt(itemSerial) !== parseInt(newSerial)) {
        let data = {
          id: e.item.getAttribute('id'),
          definition_id: this.$route.params.definition_id,
          new_order: newSerial
        };
        this.$Progress.start();
        this.$store.dispatch("definition/reorderItem", data).then(response => {
          this.$Progress.finish();
        });
      }
    }
  }
}
</script>

<style scoped>
.i-mr-65 {
  margin-right: 65px;
}
.def-full-screen {
  width: 1920px;
  height: 1080px;
}
.definition-content-section {
  width: 1510px;
  margin-top: 14px;
}
.definition-content-full-screen {
  width: 1920px;
}
.content-header-fullscreen {
  left: 40px;
  top: 40px;
}
.def-item-ml {
  margin-left: 33px;
}
.definition-show-side {
  height: 870px;
}
.list-move{
  transition: .5s;
}

/* @media all and (max-width: 1850px){
  .definition-content-section{
    width: 100%;
    padding-right: 70px;
  }
} */
</style>
