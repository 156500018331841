<template>
  <div>
    <div class="">
      <div class="relative">
        <!-- <img v-if="previewURL" :src="previewURL" alt="image" class="block w-full"> -->
        <img :src="(definitionSet?.file) ? definitionSet?.file?.file_path : '/images/build/demo_image_1.jpg'" alt="demo" class="def-image rounded shadow-two">
<!--         <label v-if="canEdit(project.cans)"  class="absolute right-1 bottom-1 property-image-icon cursor-pointer">
          <input @input="(e)=>{onChange(e), updateImage(
                  {file, id:definitionSet.id}, 
                  ()=>{
                    previewURL = ''
                  }
              )}"
              hidden type="file" accept="image/x-png,image/jpeg" />
          
          <svg class="pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
              <path d="M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z" fill="#E2E2E2"/>
          </svg>
        </label> -->
      </div>
      <h4 class="i-mt-28 fs-14 fw-600 text-primary-one content-w ellipse">{{ definitionSet && definitionSet.title ? definitionSet.title : 'N/A' }}</h4>

      <p class="fs-12 fw-500 text-secondary-one mt-2 text-break">
        {{ definitionSet && definitionSet.description ? definitionSet.description : 'N/A' }}
      </p>
    </div>

    <div class="i-mt-28">
      <button
        v-if="canCreate(project.cans)"
        @click="createDefinition()"
        class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one w-full mt-5 custom-rounded flex items-center justify-center">
        Create Definition
      </button>
      <router-link
        :to="{ name: 'definition', params: { id: $route.params.id } }"
        class="ws-folder-button focus:outline-none hover:bg-primary-three hover:text-primary-one text-primary-three fs-14 font-poppins fw-600 bg-primary-one shadow-one w-full mt-8 custom-rounded flex items-center justify-center">
        Go Back
      </router-link>
    </div>
  </div>
</template>

<script setup>
  // import axios from 'axios'
  // import { useFile } from '@/composable/useFile.js'
  // const { onChange, previewURL, file } = useFile()
  import usePermission from '@/composable/usePermission.js'
  const { canRead, canEdit, canCreate, canDelete } = usePermission()
    
  /* const updateImage = async ({file, id}, callback) => {
      let formData = new FormData()
      formData.append('id', id)
      formData.append('image', file)
      await axios.post('projects/scenarios/collections/image', formData)
      callback()
  } */
</script>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DefinitionData",
  computed: {
    ...mapGetters({
      definitionSet: "definition/definitionSet",
      project: "project/project"
    })
  },
  methods: {
    createDefinition() {
      if(this.definitionSet && this.definitionSet.id) {
        let data = {
          definition_id: this.definitionSet.id,
          title: 'Definition name',
          description: 'Descriptive text',
          order: this.getOrder(),
          type: this.getType(),
          range_with_definition: this.prepareRangeDefinition()
        };
        this.$Progress.start();
        this.$store.dispatch("definition/storeDefinitionItem", data).then(response => {
          this.$Progress.finish();
        });
      } else {
        alert('No definition set found');
      }
    },
    getOrder() {
      let lastItem = this.definitionSet && this.definitionSet.items && this.definitionSet.items.length > 0 ? this.definitionSet.items[this.definitionSet.items.length - 1] : null;
      if(lastItem) {
        return lastItem.order + 1;
      }
      return 1;
    },
    getType() {
      let type = 1;
      let canSliderDuplicated = this.definitionSet.items.findIndex(item => parseInt(item.type) === 1);
      if(canSliderDuplicated >= 0) {
        type = 2
        let canLevelDuplicated = this.definitionSet.items.findIndex(item => parseInt(item.type) === 2);
        if(canLevelDuplicated >= 0) {
          type = 3;
        }
      }
      return type;
    },
    prepareRangeDefinition() {
      let data = [];
      let range = ['0-2', '2-4', '4-6', '6-8', '8-10'];
      let canSliderDuplicated = this.definitionSet.items.findIndex(item => parseInt(item.type) === 1);
      if(canSliderDuplicated >= 0) {
        range = ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'];
        let canLevelDuplicated = this.definitionSet.items.findIndex(item => parseInt(item.type) === 2);
        if(canLevelDuplicated >= 0) {
          range = ['', '', '', '', ''];
        }
      }

      for (let i = 0; i <= 4; i++) {
        data.push({ range: range[i], definition: '' });
      }
      return data;
    }
  }
}
</script>

<style scoped>
.def-image {
  width: 275px;
  height: 132px;
}
.content-w {
  width: 275px;
}
.text-break {
  word-break: break-all;
  white-space: normal;
}
.custom-rounded {
  border-radius: 4px;
}
</style>