<template>
  <div class="definition-item font-poppins">
    <div class="flex justify-between items-center pl-5">
      <div class="flex items-center">
        <h4 class="fs-12 fw-500 text-secondary-one">Type:</h4>
        <select 
          :disabled="!canCreate(project.cans)"
          class="fs-12 fw-500 text-secondary-one pr-select-dropdown bg-transparent i-ml-10"
          v-model="item.type"
          @change="updateDefinitionType($event)">

          <option value="1" :disabled="isSliderExist" :class="{'opacity-50': isSliderExist}">Slider Definition</option>
          <option value="2" :disabled="isLevelExist" :class="{'opacity-50': isLevelExist}">Level Definition</option>
          <option value="3">Business Definition</option>
        </select>
      </div>

      <div class="flex items-center">
        <img 
          v-show="canCreate(project.cans)"
          src="@/assets/images/definition/duplicate.svg" alt="icon"
          class="definition-icon cursor-pointer"
          content="Duplicate Definition"
          v-tippy="{ placement : 'top',  arrow: true }"
          @click="duplicateDefinition()"
          v-if="item.type === 3" />
        <img 
          v-if="canDelete(project.cans)"
          src="@/assets/images/definition/delete.svg" alt="icon"
          class="definition-icon cursor-pointer i-ml-10"
          content="Delete Definition"
          v-tippy="{ placement : 'top',  arrow: true }"
          @click="deleteDefinition()" />
      </div>
    </div>

    <div class="definition-section i-mt-10 bg-primary-three px-5 py-5 rounded shadow-one overflow-y-auto">
      <input 
        :disabled="!canEdit(project.cans)"
        class="fs-14 fw-bold text-primary-one bg-transparent no-focus w-full" 
        v-model="item.title"
        @change="updateDefinitionItem()" />
      <div class="i-pb-25 i-border-b-1 border-secondary-five">
        <textarea 
          :disabled="!canEdit(project.cans)"
          class="fs-12 text-secondary-one i-mt-6 definition-description overflow-y-auto no-focus bg-transparent bld-content-area" 
          v-model="item.description"
          @change="updateDefinitionItem()"/>
      </div>

      <div class="definition-range overflow-y-auto flex items-center border-secondary-five"
           v-for="(defRow, index) in getOrderedRanges"
           :key="index"
           :class="{'i-border-t-1': index !== 0}">
          
        <p class="fs-14 text-primary-one fw-600 w-16" v-if="item.type !== 3">{{ defRow.range }}:</p>
        <p class="fs-14 text-primary-one fw-500 def-section" 
          :contenteditable="true"
          :class="{'full-definition': item.type === 3, 'pointer-events-none': !canEdit(project.cans)}"
          v-once
          placeholder="Type here"
          @input="changeDefinitionRow(index, $event)"
          @click="editableIndex = index">{{ getDefinitionText(defRow.definition) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canRead, canEdit, canCreate, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DefinitionItem",
  props: ['item'],
  data() {
    return {
      editableIndex: null
    }
  },
  computed: {
    ...mapGetters({
      definitionSet: "definition/definitionSet",
      project: "project/project"
    }),
    getOrderedRanges() {
      if(this.item && this.item.range_with_definition && this.item.range_with_definition.length > 0) {
        let data = this.item.range_with_definition.slice();
        return data.reverse();
      }
      return [];
    },
    isSliderExist() {
      let isExist = this.definitionSet.items.findIndex(item => parseInt(item.type) === 1);
      return isExist >= 0;
    },
    isLevelExist() {
      let isExist = this.definitionSet.items.findIndex(item => parseInt(item.type) === 2);
      return isExist >= 0;
    }
  },
  methods: {
    duplicateDefinition() {
      if(this.item.type === 1 || this.item.type === 2) {
        let canDuplicated = this.definitionSet.items.findIndex(item => parseInt(item.type) === 1 || parseInt(this.item.type) === 2);
        if(canDuplicated >= 0) {
          alert('Sorry, Slider or level can be used one in a set');
        } else {
          this.duplicate();
        }
      } else {
        this.duplicate();
      }
    },
    duplicate() {
      this.$Progress.start();
      this.$store.dispatch("definition/duplicateDefinitionItem", {
        definition_item_id: this.item.id,
        definition_id: this.$route.params.definition_id
      }).then(response => {
        this.$Progress.finish();
      });
    },
    deleteDefinition() {
      this.$Progress.start();
      this.$store.dispatch("definition/deleteDefinitionItem", {
        definition_item_id: this.item.id,
        definition_id: this.$route.params.definition_id
      }).then(response => {
        this.$Progress.finish();
      });
    },
    updateDefinitionType(event) {
      event.preventDefault();
      this.updateDefinitionItem();
    },
    changeDefinitionRow(index, event) {
      let defData = Object.assign({}, this.item);
      defData.definition_item_id = defData.id;
      defData.index = index;
      defData.definition = event.target.innerText;
      this.$store.dispatch("definition/updateDefinitionRowItem", defData);
    },
    updateDefinitionItem() {
      this.$store.dispatch("definition/updateDefinitionItem", this.item);
    },
    getDefinitionText(text) {
      return text && text.length > 0 ? text : null;
    }
  }
}
</script>

<style scoped>
.definition-item {
  width: 340px;
}
.definition-icon {
  width: 24px;
  height: 24px;
}
.definition-section {
  width: 340px;
  height: 875px;
}
.definition-description {
  width: 294px;
  height: 72px;
}
.text-break {
  word-break: break-all;
  white-space: normal;
}
.definition-range {
  height: 140px;
  width: 294px;
}
.range-section {
  width: 60px;
}
.def-section {
  width: 234px;
}
.def-section:focus {
  border: none;
  outline: none;
}
.no-focus:focus {
  border: none;
  outline: none;
}
.full-definition {
  width: 294px;
}
</style>