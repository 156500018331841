import { render, staticRenderFns } from "./Show.vue?vue&type=template&id=819b4dc2&scoped=true&"
import script from "./Show.vue?vue&type=script&setup=true&lang=js&"
export * from "./Show.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Show.vue?vue&type=style&index=0&id=819b4dc2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "819b4dc2",
  null
  
)

export default component.exports